<template>
  <li data-test="usiconlistitem" class="us-icon-list-item">
    <USIcon
      :name="icon"
      :color="color"
      :fill="fill"
      :background="true"
      :css-diameter="cssDiameter"
    />
    <div class="us-icon-list-item__content">
      <slot />
    </div>
  </li>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import USIcon from '../USIcon/USIcon.vue'

export default defineComponent({
  name: 'USIconListItem',
  components: {
    USIcon
  },
  props: {
    icon: { type: String },
    fill: { type: String },
    color: { type: String },
    cssDiameter: { type: String, default: '' }
  }
})
</script>

<style lang="scss" scoped>
.us-icon-list-item {
  align-items: center;
  display: flex;
  flex-shrink: 0;

  &__content {
    padding-left: var(--spacing--6);
  }
}
</style>
