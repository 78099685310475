<script lang="ts">
import { defineComponent, h, PropType } from 'vue'

export default defineComponent({
  name: 'USList',
  props: {
    type: {
      type: String as PropType<'ordered' | 'unordered' | 'icon'>,
      default: 'unordered'
    },
    columns: {
      type: Number,
      default: 1,
      validator: (val: number) => val > 0 && val < 8
    }
  },
  render() {
    return h(
      this.type == 'ordered' ? 'ol' : 'ul', // tag name
      {
        'data-test': 'uslist',
        class: [
          'us-list',
          `us-list--${this.type}`,
          `us-list--columns-${this.columns}`
        ]
      },
      this.$slots.default ? this.$slots.default() : ''
    )
  }
})
</script>

<style lang="scss" scoped>
@use 'sass:math';

.us-list {
  counter-reset: ordered-counter;
  display: flex;
  flex-flow: row wrap;
  font-size: var(--font-size-p1);
  list-style: none;
  padding: 50px;

  :deep(li) {
    display: flex;
    flex: 0 0 100%;
    margin-top: 20px;

    &::before {
      flex-shrink: 0;
      margin-right: 20px;
    }
  }

  &--columns-2 {
    :deep(li) {
      padding-right: 40px;
    }
  }

  $width-map: (
    1: 0,
    2: 340,
    3: 600,
    4: 800,
    5: 1200,
    6: 1400
  );

  @for $i from 1 through 6 {
    &--columns-#{$i} {
      @extend .us-list--columns-#{$i - 1} !optional;

      @media (min-width: map-get($width-map, $i) +
            'px') {
        :deep(li) {
          flex-basis: #{math.div(100, $i)} + '%';

          &:nth-child(-n + #{$i}) {
            margin-top: 0;
          }
        }
      }
    }
  }

  &--unordered {
    :deep(li) {
      align-items: flex-start;

      &:before {
        content: '';
        background-color: black;
        border-radius: 50%;
        display: inline-block;
        height: 6px;
        width: 6px;
        margin-top: 0.3em;
      }
    }
  }

  &--ordered {
    :deep(li) {
      align-items: center;
      counter-increment: ordered-counter;

      &:before {
        $diameter: 30px;

        content: counter(ordered-counter);
        align-items: center;
        border-radius: 50%;
        border: 1px solid #71c5e8;
        display: flex;
        height: $diameter;
        justify-content: center;
        width: $diameter;
      }
    }
  }
}
</style>
